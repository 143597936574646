<template>
    <div>
        <ts-page-title
            :title="$t('dropPackage.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('dropPackage.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between">
                    <a-range-picker
                        v-model="dateRequest"
                        :allowClear="false"
                        format="DD-MM-YYYY"
                        valueFormat="DD-MM-YYYY"
                        style="width: 200px"
                        @change="fetchData()"
                    />
                    <a-tooltip
                        placement="topLeft"
                        :title="$t('dropPackage.search')"
                    >
                        <a-input
                            v-model="search"
                            v-tooltip="$t('dropPackage.search')"
                            :placeholder="$t('dropPackage.search')"
                            style="width: 250px"
                            class="inline-block"
                        />
                    </a-tooltip>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-x-scroll">
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                    :expandable="true"
                >
                    <template v-slot="{ record }">
                        <td>{{ record.dropped_time }}</td>
                        <td>{{ record.agency_code }}</td>
                        <td>{{ record.agency_name }}</td>
                        <td>{{ record.driver_code }}</td>
                        <td>{{ record.driver_name }}</td>
                        <td class="tw-text-center">{{ record.sex }}</td>
                        <td class="tw-text-center">{{ record.packages }}</td>
                        <td class="tw-text-center">
                            {{ record.received_by_name }}
                        </td>
                        <td class="tw-text-center tw-whitespace-nowrap">
                            {{ record.received_time }}
                        </td>
                        <td class="tw-text-center">
                            <!-- v-if="$can('create-driver-drop-packages')" -->
                            <a-popconfirm
                                placement="leftTop"
                                :title="$t('are_you_sure_to_receive')"
                                :ok-text="$t('yes')"
                                :cancel-text="$t('no')"
                                @confirm="onSave(record)"
																v-if="!record.received_by_name"
                            >
                                <a
                                    href="#"
                                    ><i class="fas fa-clipboard-check"></i
                                ></a>
                            </a-popconfirm>
                        </td>
                    </template>
                    <template v-slot:expand="{ record }">
                        <table class="tw-w-full table">
                            <thead>
                                <tr>
                                    <th class="tw-w-3 tw-text-center">#</th>
                                    <th>{{ $t("dropPackage.voucherNo") }}</th>
                                    <th>
                                        {{ $t("dropPackage.deliveryNumber") }}
                                    </th>
                                    <th>
                                        {{ $t("dropPackage.bookingNumber") }}
                                    </th>
                                    <th>
                                        {{ $t("dropPackage.receiverPhone") }}
                                    </th>
                                    <th>
                                        {{ $t("dropPackage.receiverLocation") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(d, index) in record.details"
                                    :key="index"
                                >
                                    <td class="tw-text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td>{{ d.voucher_no }}</td>
                                    <td>{{ d.delivery_number }}</td>
                                    <td>{{ d.booking_number }}</td>
                                    <td>{{ d.receiver_phone }}</td>
                                    <td>{{ d.receiver_location }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </ts-table>
            </div>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
    name: "dropPackageIndex",
    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapState("agency/driverDropPackage", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.agency.driverDropPackage.search;
            },
            set(newValue) {
                this.$store.commit(
                    "agency/driverDropPackage/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "agency/driverDropPackage/RESET_CURRENT_PAGE"
                );
            }
        },
        dateRequest: {
            get() {
                return this.$store.state.agency.driverDropPackage.dateRange;
            },
            set(newValue) {
                this.$store.commit(
                    "agency/driverDropPackage/SET_DROP_DATE",
                    newValue
                );
            }
        },
        columns() {
            return [
                { name: this.$t("dropPackage.date") },
                { name: this.$t("dropPackage.agencyCode") },
                { name: this.$t("dropPackage.agencyName") },
                { name: this.$t("dropPackage.driverCode") },
                { name: this.$t("dropPackage.driverName") },
                {
                    name: this.$t("dropPackage.sex"),
                    style: "width:80px; text-align:center"
                },
                {
                    name: this.$t("dropPackage.package"),
                    style: "width:100px; text-align:center"
                },
                {
                    name: this.$t("dropPackage.receivedBy"),
                    style: "width:100px; text-align:center"
                },
                {
                    name: this.$t("dropPackage.dateReceived"),
                    style: "width:100px; text-align:center"
                },
                {
                    name: this.$t("actions"),
                    style: "text-align:center; width: 1px;"
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("agency/driverDropPackage/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        onSave(record) {
            this.loading = true;
            this.$store
                .dispatch("agency/driverDropPackage/store", {
                    delivery_id: record.details.map(el => el.delivery_id)
                })
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("agency/driverDropPackage/RESET_STATE");
        next();
    }
};
</script>
